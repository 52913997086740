import React, { useEffect, useMemo, useRef } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import styled from 'styled-components';
import Image from 'next/image';

const AddAccountSpan = styled.span`
    margin-top: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    height: 54px;
    border-radius: 27px;
    background-color: #fff;
    color: var(--app-primary-color);
    cursor: pointer;
`;
const UseShowContent = () => {
  const { t } = useTranslation('common');
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (ref.current) {
              ref.current.style.backgroundImage = `url('/images/home/use-icon.webp')`;
            }
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const dataList = [
    t('SignInAndAccessTheDashboard'),
    t('AddAccount_Desc'),
    t('WaitForTheDetectionPeriod_Desc'),
  ];

  const getContent = useMemo(() => {
    return (
      <div className={styles.content} ref={ref}>
        <h2>{t('HowToUseDolphinRadar')}</h2>

        <div className={styles.stepContainer}>
          {dataList.map((item, index) => {
            return (
              <div className={styles.cardContainer} key={index}>
                <div className={styles.lineContainer}>
                  <Image
                    src="/images/home/use-step-icon.webp"
                    alt="step"
                    className={styles.lineImg}
                    width={34}
                    height={34}
                  />
                  <div className={styles.line} />
                </div>
                <div className={styles.contentContainer}>
                  <div className={styles.title}>{`${t('Step')}: ${index + 1}`}</div>
                  <div className={styles.desc}>{item}</div>
                </div>
              </div>
            );
          })}
        </div>

        <Link href={`/dashboard?alertType=1`}>
          <AddAccountSpan>{`${t("ADD ACCOUNT")} >`}</AddAccountSpan>
        </Link>
      </div>
    );
  }, [ref, dataList]);

  return <>{getContent}</>;
};

export default UseShowContent;
